import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container, Grid,
    Typography,
} from "@mui/material";
import {Helmet} from "react-helmet";

import {routes} from "../../config/routes";

import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const Index = () => {
    const navigate = useNavigate();

    // const { isTenant } = useAuth();

    const {t} = useTranslation();

    // const [transactions, setTransactions] = useState<Transaction[]>();

    // const { properties, isLoading: isLoadingProperties } = useGetUserProperties({
    //   userId: user?.id,
    // });

    // useEffect(() => {
    //   // setTransactions(transactionsMocks);

    //   // if not bank account connected
    //   if (!isLoadingLandlord && landlord?.requisitionId === null) {
    //     navigate(routes.connectBank);
    //   }
    // }, [landlord, isLoadingLandlord]);

    // const handleEditTransaction = () => {
    //   alert("edit transaction");
    // };

    // const handleGoToProperty = (property: Property) =>
    //   navigate(routes.property.base, { state: { property } });

    // const handleGoToCreateProperty = () =>
    //   navigate(`${routes.property.base}/${routes.property.create}`);

    const cardComponent = (title: string, content: string, buttonText: string, route: string) => {
        return (
            <Card sx={{
                padding: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}>
                <CardHeader title={title}/>
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                    }}
                >
                    <Box sx={{flexGrow: 1}}>
                        {content}
                    </Box>
                    <Box sx={{mt: 'auto'}}>
                        <Button
                            onClick={() => navigate(route)}
                            variant="contained"
                            sx={{
                                left: -10,
                                marginTop: '30px',
                                // width: '250px'
                            }}
                            fullWidth
                        >
                            {buttonText}
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        )
    }
    const GoToStats = () => {
        return cardComponent(t('home.analyticsTitle') + ' 📊', t('general.analyticsGeneral'), t('home.access'), routes.analytics)
    };

    const goToLoans = () => {
        return cardComponent(t('home.loansTitle') + " 💰", t('home.loansContent'), t('general.apply'), routes.loans.base)
    };

    const goToPerks = () => {
        return cardComponent(t('home.cashbackTitle') + ' 🎁', t('home.cashbackContent'), t('general.seeBenefits'), routes.perksSniptech)
    };

    return (
        <>
            <Helmet>
                <title>Nido - Inicio</title>
            </Helmet>

            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card sx={{
                            padding: "20px"
                        }}>
                            <CardContent>
                                <Typography variant="h3" sx={{marginBottom: "20px"}}>
                                    {t("general.welcome")} 👋
                                </Typography>
                                <Typography sx={{marginBottom: 2}}>
                                    {t("general.welcomeText1")}
                                </Typography>
                                <Typography>{t("general.welcomeText2")}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* render components */}
                    <Grid item xs={12}>
                        <Grid container spacing={2}>

                            {
                                [goToLoans(), <GoToStats/>, goToPerks()].map((item) =>
                                    <Grid item xs={12} md={4}>{item}</Grid>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>

            </Container>
        </>
    );
};

export default Index;
