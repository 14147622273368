import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Box, Button, TextField, Typography } from "@mui/material";

import { signUp } from "../../../services/auth/auth";
import { routes } from "../../../config/routes";

import AuthLayout from "../AuthLayout";

const SignIn = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { control, handleSubmit, getValues } = useForm<{
    email: string;
    password: string;
    fullName: string;
  }>({
    defaultValues: {
      email: "",
      password: "",
      fullName: "",
    },
    mode: "onChange",
  });

  const handleSignUp = () => {
    handleSubmit(async () => {
      // try to sign up
      const { error } = await signUp(getValues("email"), getValues("password"));

      if (error) {
        alert(error);
      }
      //   signed in
      else {
        navigate(routes.index);
      }
    })();
  };

  // const handleGoToLogin = () => {
  //   navigate(routes.login);
  // };

  return (
    <AuthLayout>
      <Box sx={{ "& >*": { marginY: 1 } }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center",
            "& >*": { marginY: 1.5 },
          }}
        >
          <Typography variant="h3">
            {/* Create an account */}
              {t('auth.title')}
          </Typography>

          <Typography>
              {t('auth.subtitle1')}{" "}
            <a href={routes.login}>
              {" "}
              <b>{t('auth.subtitle2')}</b>
            </a>
          </Typography>
        </Box>

        <Controller
          name="fullName"
          rules={{ required: { message: "It's required", value: true } }}
          control={control}
          render={({
            field: { value, onChange, name },
            formState: { errors },
          }) => (
            <>
              <TextField
                fullWidth
                margin="normal"
                label={t('auth.fullName')}
                variant="outlined"
                value={value}
                onChange={onChange}
              />
              <Typography variant="body2" color="error.main">
                {errors[name]?.message}
              </Typography>
            </>
          )}
        />

        <Controller
          name="email"
          rules={{ required: { message: "It's required", value: true } }}
          control={control}
          render={({
            field: { value, onChange, name },
            formState: { errors },
          }) => (
            <>
              <TextField
                fullWidth
                margin="normal"
                label="Email"
                variant="outlined"
                value={value}
                onChange={onChange}
              />
              <Typography variant="body2" color="error.main">
                {errors[name]?.message}
              </Typography>
            </>
          )}
        />

        <Controller
          name="password"
          rules={{ required: { message: "It's required", value: true } }}
          control={control}
          render={({
            field: { value, onChange, name },
            formState: { errors },
          }) => (
            <>
              <TextField
                fullWidth
                margin="normal"
                label={t('auth.password')}
                variant="outlined"
                value={value}
                onChange={onChange}
                type="password"
              />
              <Typography
                variant="body2"
                style={{ fontSize: "11px", marginTop: 10, marginBottom: 20 }}
              >
                  {t('auth.passwordMessage')}
              </Typography>
              <Typography variant="body2" color="error.main">
                {errors[name]?.message}
              </Typography>
            </>
          )}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Button
            onClick={handleSignUp}
            variant="contained"
            sx={{ marginBottom: 2 }}
            size="large"
          >
            {/* Sign up */}
              {t('auth.register')}
          </Button>

          {/* <Button onClick={handleGoToLogin} variant="outlined" size="small">
            Ya tengo una cuenta
          </Button> */}
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default SignIn;
