import {
    Box,
    Button,
    ButtonGroup,
    Container,
} from "@mui/material";
import {useState} from "react";

import GeneralStats from "./components/GeneralStats";
import Loader from "../../components/Loader";
import PageTitleWrapper from "components/PageTitleWrapper";
import PageHeader from "./PageHeader";

import {timeOptions} from "../../components/barChart/constants";
import {useGetUserStats} from "../../services/stats";
import {useAuth} from "../../hooks/auth";
import {getDateMonthsAgo} from "./utils";
import Charts from "../../components/barChart/Charts";
import {useGetLandlordProfile} from "../../services/landlord/landlord";
import GoToBankComponent from "../components/goToBank";
import ExpensesAnalysis from "./components/ExpensesAnalysis";
import {useGetUserTransactions} from "../../services/bankInfo";

const Analytics = () => {

        // set today as initial value
        const [dateTo, setDateTo] = useState<Date | undefined>(new Date());

        // this change and is choosen by user
        const [dateFrom, setDateFrom] = useState<Date | undefined>(getDateMonthsAgo(12));

        const {user} = useAuth();

        const {landlord} = useGetLandlordProfile({userAuthId: user.id});

        const {stats, isLoading} = useGetUserStats({
            userId: user.id,
            dateFrom,
            dateTo,
            enabled: !!landlord ? landlord.requisitionId !== undefined : false,
        })

        // this is to update transactions from DB
        const {isLoading: isLoadingTransactions} = useGetUserTransactions({
            userId: user?.id,
            dateTo: new Date().toString(),
            requisitionId: landlord?.requisitionId,
            lastTransactionDate: landlord?.lastTransactionDate,
            country: landlord?.bankAccountCountry
        })

        const [timeSelected, setTimeSelected] = useState<
            (typeof timeOptions)[number] | undefined
        >(timeOptions[0]);

        const handleSelectTimeChart = (time: (typeof timeOptions)[number]) => {
            // update title
            setTimeSelected(time);

            // update date to and date from
            setDateFrom(getDateMonthsAgo(time.value));

        };

        const renderStats = () => (
            <>
                {/* select time */}
                <Box sx={{display: "flex", justifyContent: "space-around"}}>
                    {/* select time length */}
                    <ButtonGroup>
                        {timeOptions.map((time) => (
                            <Button
                                key={time.value}
                                size="small"
                                onClick={() => handleSelectTimeChart(time)}
                                sx={{
                                    background: !!timeSelected && time.value === timeSelected.value ? "rgba(187, 173, 249, 0.2)" : ""
                                }}
                            >
                                {time.label}
                            </Button>
                        ))}
                    </ButtonGroup>

                </Box>

                {

                    !!stats && (
                        <>
                            <GeneralStats stats={stats['generalStats']}/>

                            {timeSelected && <Charts
                                byMonthIncomesExpensesData={stats['byMonthStats']}
                            />}

                            {/*<ExpensesAnalysis expenses={stats['expensesBreakdown']} incomes={stats['incomesBreakdown']}/>*/}
                        </>
                    )
                }

            </>
        )

        const renderGoToBankComponent = () => <GoToBankComponent/>;

        const mainRender = () => {
            if (landlord?.requisitionId !== null) {
                return renderStats()
            } else {
                return renderGoToBankComponent()
            }
        }

        return (
            <>
                {/*<Helmet>*/}
                {/*    <title>Nido - Home</title>*/}
                {/*</Helmet>*/}

                <PageTitleWrapper>
                    <PageHeader/>
                </PageTitleWrapper>

                <Container maxWidth="lg">
                    {(!isLoading
                        && !isLoadingTransactions
                    ) ? mainRender() :
                        <Loader/>
                    }
                </Container>
            </>
        );
    }
;

export default Analytics;
