import {supabase} from "../../config/supabaseClient";

export const signUp = async (email: string, password: string) => {
    let {data, error} = await supabase.auth.signUp({
        email: email,
        password: password,
    });

    // create landlord in database table
    await supabase
        .from("Landlord")
        // .insert([{ user: data.user?.id }]);
        .insert({
            userAuthId: data.user.id
        })

    return {data, error};
};

export const logout = async () => {
    const {error} = await supabase.auth.signOut();

    return error;
};

export const login = async (email: string, password: string) => {
    let {data, error} = await supabase.auth.signInWithPassword({
        email,
        password,
    });

    return {data, error};
};
