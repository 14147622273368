import {Box, Button, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

const HelpButton = () => {

    const {t} = useTranslation();

    const handleHelp = () => {

        if (window.confirm('Te enviaremos a nuestro Whatsapp de soporte tecnico, en donde te resolveremos cualquier duda lo antes posible.')) {
            const phoneNumber = process.env.REACT_APP_CUSTOMER_WSP_NUMBER || '56937827142'
            const text = 'Hola. Necesito ayuda con Nido';

            const url = `https://wa.me/+${phoneNumber}?text=${text}`;

            window.open(url);
        }
    }

    return <Box style={{
        display: "flex",
        marginLeft: '30px',
        marginTop: '20px'
    }}>
        <a href="mailto:example@example.com">
            <Button
                variant='text'
            >
                <Typography sx={{color: 'rgba(181,184,189,255)', fontWeight: 'bold'}}>
                    {t('general.needHelp')} 🧑‍💻
                </Typography>
            </Button>
        </a>
    </Box>
}

export default HelpButton;