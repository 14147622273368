import {Container} from "@mui/material";

import LanguageSelector from "../../components/languageSelector";
import logo from "../../static/logo.png";

type Props = {
    children: JSX.Element;
};

const AuthLayout = (props: Props) => {
    const {children} = props;
    // const isLargeScreen = useMediaQuery("(min-width: 1280px)");

    return (
        <>
            <Container
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 10,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: 'space-between',
                        width: "100%",
                    }}
                >
                    <img src={logo} alt="Logo" width={150}/>
                    <LanguageSelector/>
                </div>
            </Container>
            <Container maxWidth="sm">{children}</Container>
        </>
    );
};

export default AuthLayout;
