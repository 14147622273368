export const routes = {
    // auth
    signIn: "/signIn",
    login: "/login",
    loginTenant: "/loginTenant",

    // pages
    index: "/",
    analytics: "/analytics",
    transactions: "/transactions",

    // properties
    property: {
        base: "property",
        create: "create",
        edit: "edit",
    },

    perks: "/perks",
    perksSniptech: "/perksSniptech",
    perksSniptechDashboard: "/perksSniptechDashboard",

    // connect to bank
    connectBank: "/connectBank",

    setupTaxes: "/setupTaxes",
    taxes: "/taxes",

    // landlord profile
    landlord: {
        base: "landlord",
        profile: "profile",
        bankInfo: "bankInfo",
        signature: "signature",
    },

    // contracts
    contracts: {
        base: "contracts",
        property: "property",
        create: "create",
        guestPortal: "guestPortal",
        viewer: "viewer",
        details: "details",
    },

    // loans
    loans: {
        base: 'loans',
        connectBank: 'connectBank',
        congratulations: 'congratulations',
        newLoanForm: 'newLoanForm'
    }
};
